import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";

import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import './bannerAnimation.scss';
import AppIcons from '../../assets/images/icons';

import { translateThis } from '../../helpers/language.helper';
import EvolveAppHeader from './evolveHeader';

import PPContactForm from './PPcontactForm/PPContactForm';
import SVGIcons from '../../assets/images/icons/svgIcons';





const EvolveChildCareHomePage = () => {

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('evolve-home');
        }, 500);
        // eslint-disable-next-line
    });

    const [openPPForm, setOpenPPForm] = useState(false);

    const handleOpenPPForm = () => {
        setOpenPPForm(true);
    };
    const handleClosePPForm = () => {
        setOpenPPForm(false);
    };

    return (
        <>

            <AppLayout
                // pageName={pageName}
                // isLoadHeader={false}
                isLoadFooter={false}
                customHeader={<EvolveAppHeader />}
                bodyClassName='home-main-wrp f-wrp'
            >
                <>
                    <div className='evolve-page-main-wrapper f-wrp'>

                        <div className='evolve-banner-con f-wrp'>
                            <div className='evolve-banner-img'>
                                <span><img src={AppIcons.evolveBannerBG} alt='banner' /></span>
                            </div>
                            <div className='evolveBanner-con'>
                                <div className='evolveBanner-sec-con'>
                                    <span><img src={AppIcons.evolveBannerLogo} alt='logo' /></span>
                                    <p>{translateThis('evolveBannerText')}</p>

                                    {/* <Link onClick={() => window.open('https://www.evolvechildcare.com/ppplatform/')} className='sample-btn' >{translateThis('Check PP platform')}</Link> */}

                                    <Link to={'/ppplatform'} className='sample-btn'>{translateThis('Check PP platform')}</Link>
                                    <Button onClick={handleOpenPPForm} className='need-a-btn'>{translateThis('Do you need a website')}</Button>
                                    {/* {(getAuthRoleName()) ? <Button onClick={() => {
                                                                window.location.href = `/${facilityData?.facilityURL}/my-portal`
                                                            }} className='sample-btn'>Access Portal
                                                            </Button> : <Link to={`/${facilityData?.facilityURL}/auth/login`} className='sample-btn' > Access Portal </Link>} */}
                                </div>
                            </div>
                        </div>
                        <div className='evolve-scrolling-sec f-wrp'>

                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <div style={{ padding: '0 75px' }}> {translateThis('evolveScrollingText')} </div>
                            </Marquee>
                        </div>
                    </div>
                </>
                <Modal
                    className='ppContact-popup'
                    open={openPPForm}
                    onClose={handleClosePPForm}
                    aria-labelledby="ppContact-popup"
                    aria-describedby="ppContact-popup"
                >
                    <div className='pp-contact-form f-wrp'>
                        <div className='pp-contact-head'>
                            <p>🚀 Do you need a website? 📱 💻</p>
                            <Button onClick={handleClosePPForm}>{SVGIcons.CrossIcon()}</Button>
                        </div>

                        <PPContactForm onSuccess={handleClosePPForm} />
                    </div>
                </Modal>
            </AppLayout>
        </>
    )
}

export default EvolveChildCareHomePage;